'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:ReportsPromoCodeSalesCtrl
 * @description
 * # ReportsPromoCodeSalesCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('ReportsPromoCodeSalesCtrl', function ($scope, api) {
		$scope.q = {
			dateFrom: null,
			dateTo: null
		};

		$scope.generateReport = function () {
			api.runReport('promo-code-sales', {params:$scope.q}).then(function (data) {
				$scope.data = data;
			});
		};
	});
