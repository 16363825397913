'use strict';

/**
 * @ngdoc directive
 * @name 360StoreAngularApp.directive:shoppingCart
 * @description
 * # shoppingCart
 */
angular.module('360StoreAngularApp')
	.directive('shoppingCart', function () {
		function extendedPrice(item) {
			return item ? item.unitPrice * item.qty : 0;
		}

		return {
			templateUrl: 'views/shopping-cart.html',
			restrict: 'E',
			scope: {
				cartActions: '<',
				promoName: '<?',
				promoDollarAmount: '<?',
			},
			link: function(scope) {
				scope.deletePromoCode = function () {
					scope.$emit('remove-promo');
				};

				scope.removeCartAction = function (cartAction) {
					scope.$emit('remove-cart-action', cartAction);
				};

				scope.totalWithoutPromo = function () {
					return scope.cartActions.reduce(function (sum, action) {
						return sum + action.lineItems.reduce(function(sum2, item) {
							return sum2 + extendedPrice(item);
						}, 0);
					}, 0);
				};

				scope.totalOrderPrice = function () {
					return scope.totalWithoutPromo() - scope.promoDollarAmount;
				};

			}
		};
	});
