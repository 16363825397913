'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:ReportsSalesByDateCtrl
 * @description
 * # ReportsSalesByDateCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('ReportsSalesByDateCtrl', function ($scope, api) {
		$scope.tmp = {
			dateFromOpen:true,
			dateToOpen:false,
		};
		$scope.q = {
			dateFrom:  new Date().toLocaleDateString('sv-SE'),
			dateTo:  new Date().toLocaleDateString('sv-SE')
		};
		/**
		 * @type {ProductLine[]}
		 */
		$scope.productLineOptions = [];

		$scope.generateReport = function () {
			api.runReport('sales-by-date', {params: $scope.q}).then(function (data) {
				$scope.data = data;
			});
		};

		api.getProductsAll().then(products => $scope.productLineOptions = products);
	});
