'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:ReceiptCtrl
 * @description
 * # ReceiptCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('ReceiptCtrl', function ($scope, $window, receiptHtml) {
		$scope.receiptHtml = receiptHtml;

		$window.scrollTo(0, 0);
	});
