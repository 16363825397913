'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:UnsubscribeCtrl
 * @description
 * # UnsubscribeCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('UnsubscribeCtrl', function ($scope, $location, api) {
		$scope.action='gather';
		$scope.payload = {
			email: $location.search().email,
		};

		$scope.unsubscribe = async () => {
			await api.post('api/unsubscribe', null, {params:$scope.payload});
			$scope.action='done';
		};

	});
