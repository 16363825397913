'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:ModalUpsellCtrl
 * @description
 * # ModalUpsellCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('ModalUpsellCtrl', function () {
	});
