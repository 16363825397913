'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:AdminLicenseToolCtrl
 * @description
 * # AdminLicenseToolCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('AdminLicenseToolCtrl', function ($scope, api) {
		$scope.tmp = {
			licenseKey: '',
			selectedProduct: undefined,
			selectedVersion: undefined,
			selectedVariant: undefined,
		};
		$scope.generateParams = {};

		$scope.parsed = undefined;

		/**
		 * @type {string}
		 */
		$scope.generated = undefined;

		/**
		 * @type {ProductLine[]}
		 */
		$scope.productOptions = [];

		$scope.generateLicenseKey = async (payload) => {
			$scope.generated = await api.generateLicenseKey(payload);
		};

		$scope.parseLicenseKey = async (licenseKey) => {
			$scope.parsed = await api.parseLicenseKey(licenseKey);
			$scope.generateParams = angular.copy($scope.parsed);
			$scope.tmp.selectedProduct = $scope.productOptions.find(p => p.id === $scope.parsed.productId);
			let detailedProduct = await api.getProduct($scope.tmp.selectedProduct.code);
			$scope.tmp.selectedVersion = detailedProduct.versions.find(v => v.versionNumber === $scope.parsed.versionNumber);
			$scope.generateParams.variant = $scope.variantTypes.find(v => v.id===$scope.parsed.variantByte);
		};

		$scope.copyLicenseKey = () => {
			navigator.clipboard.writeText($scope.generated).then();
			toastr.success('Copied', null, {timeOut: 1000});
		};

		$scope.variantOptions = () => {
			/**
			 * @type {ProductVersion}
			 */
			const version = $scope.tmp.selectedVersion;
			if (!version?.variants?.length) {
				return [];
			}
			if (!version.variantOptions) {
				// version.variantOptions = version.variants
				// 	.map(variant => variant.variantType)
				// 	.filter(vt => !vt.upgradeFromVariant)
				// 	.reduce((byByte, vt) => {
				// 		let oldValue = byByte[vt.byteValue];
				// 		byByte[vt.byteValue] = oldValue ? (oldValue + ', ' + vt.name) : vt.name;
				// 		return byByte;
				// 	}, {});
				version.variantOptions = $scope.variantTypes.filter(vt => {
					return version.variants.find(versionVariant => versionVariant.variantType.byteValue === vt.byteValue);
				});
			}
			return version.variantOptions;
		};
		/*
		///////////////////////////////////////////////////////////////////////////
		// INIT
		///////////////////////////////////////////////////////////////////////////
		*/

		api.getProductsAll().then(found => $scope.productOptions = found);

		api.adminVariantTypes().then(response => $scope.variantTypes = response.filter(vt => vt.byteValue !== 0));

		$scope.$watch('tmp.selectedProduct', async (p) => {
			if (p?.id && !p.versions) {
				let detailedProduct = await api.getProduct(p.code);
				p.versions = detailedProduct.versions;
			}
		});
	});
