'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:LicensesCtrl
 * @description
 * # LicensesCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('LicensesCtrl', function ($scope, $location, $route, session, api) {
		/**
		 * @type {?MaintenanceSupport}
		 */
		$scope.support = null;
		$scope.credentials = {
			licenseKey: localStorage.licenseKey,
			registeredTo: localStorage.registeredTo
		};

		$scope.$on('licenseEntry', function (license) {
			$scope.license = license;
		});

		$scope.login = function () {
			api.getRenewalCandidates($scope.credentials.licenseKey, $scope.credentials.registeredTo)
				.then(function (response) {
					$scope.support = response;
					localStorage.setItem('licenseKey', $scope.credentials.licenseKey);
					localStorage.setItem('registeredTo', $scope.credentials.registeredTo);
				});
		};

		$scope.enterDifferentLicenseKey = function () {
			$scope.support = null;
		};

		/*
			  $scope.support.options = [
				  {cartItemId: 123, company:'Yoyodyne', name: '360Works Scribe', renewMe: true, renewalDate:'2017-02-12', expires: '2016-02-04', unitPrice: 695, renewalPrice: 173.75},
				  {cartItemId: 84, company:'Yoyodyne', name: '360Works SafetyNet', autoRenew:true, renewMe: false, expires: '2017-06-19', unitPrice: 195, renewalPrice: 48.75}
			  ];
		*/

		$scope.maintenanceEnd = function (item) {
			const when = moment(item.expires);
			return when.fromNow() + '! (' + when.format('M/D/YY') + ')';
		};
		$scope.setAllRenewMe = function (yn) {
			$scope.support.options.filter(option => !option.autoRenew).forEach(option => option.renewMe = yn);
		};

		$scope.atLeastOneRenewMe = function () {
			for (let i = 0; i < $scope.support.options.length; i++) {
				const item = $scope.support.options[i];
				if (item.renewMe) {
					return true;
				}
			}
			return false;
		};
		$scope.maintenanceEndAfterRenewal = function (item) {
			return moment(item.renewalDate).toDate();
			//                return moment(item.expires).add(1, 'year').toDate()
		};
		$scope.toggle = function (item) {
			if (item.disabled) {
				return;
			}
			item.renewMe = !item.renewMe;
			window.event.stopPropagation();
		};

		$scope.proceed = function () {
			const requestActions = $scope.support.options
				.filter(function (o) {
					return o.renewMe;
				})
				.map(function (o) {
					return {action: 'Renew', cartItemId: o.cartItemId, registeredTo: o.company};
				});
			api.createCartAction(requestActions)
				.then(function (action) {
					session.addCartAction(action);
					$location.path('/checkout');
				});
			// session.addRenewalCartItems({items: $scope.support.options, licenseKey: $scope.credentials.licenseKey})
			// 	.then(function() {
			// 		$location.path('/checkout');
			// 	});
		};

		/*
		///////////////////////////////////////////////////////////////////////////
		// INIT
		///////////////////////////////////////////////////////////////////////////
		*/
		const params = $route.current.params;
		if (params.licenseKey) {
			$scope.credentials = params;
			$scope.login();
		}


	});
