'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:ModalProductEditCtrl
 * @description
 * # ModalProductEditCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('ModalProductEditCtrl', function ($scope, api, product) {
		if (!product.versions) {
			product.versions = [];
		}

		if (product.versions.length === 0) {
			product.versions.push({
				name: '1.0',
				featured: true
			});
		}
		$scope.product = product;
		/**
		 * @type {{selectedVersion: ProductVersion, selectedVariant: ProductVariant | null}}
		 */
		$scope.tmp = {
			selectedVersion: product.versions.slice(-1).pop(),
			selectedVariant: null
		};

		$scope.versionAdd = () => {
			const maxVersion = Math.max(...$scope.product.versions.map(v => v.versionNumber));
			/**
			 * @type {ProductVersion}
			 */
			let newVersion = {
				variants: [],
				versionNumber: (maxVersion || 0) + 1,
			};
			$scope.product.versions.push(newVersion);
			$scope.tmp.selectedVersion = newVersion;
		};

		/**
		 * @param version {ProductVersion}
		 */
		$scope.variantAdd = (version) => {
			/**
			 * @type {ProductVariant}
			 */
			let newVariant = {};
			version.variants.push(newVariant);
			$scope.variantEdit(newVariant);
		};

		/**
		 * @param variant {ProductVariant}
		 */
		$scope.variantEdit = function (variant) {
			$scope.tmp.selectedVariant = variant;
		};

		$scope.quickSearchVariants = function (q) {
			var params = {q: q};
			if (!$scope.showAllVariants) {
				params.productId = $scope.product.id;
			}
			return api.get('api/quicksearch-variant', {params: params});
		};

		$scope.variantDone = function () {
			$scope.tmp.selectedVariant = null;
		};

		$scope.variantDownload = function (variant) {
			window.alert('Download not implemented for ' + variant);
		};

		$scope.save = function () {
			api.saveProduct(product)
				.then(function (saved) {
					$scope.$close(saved);
				});
		};
	});
