'use strict';

const DEVMODE = window.location.host === 'localhost:9360'; // jshint ignore:line

/**
 * @ngdoc overview
 * @name 360StoreAngularApp
 * @description
 * # 360StoreAngularApp
 *
 * Main module of the application.
 */
angular
	.module('360StoreAngularApp', [
		'ngAnimate',
		'ngCookies',
		'ngMessages',
		'ngRoute',
		'ngTouch',
		'angularMoment',
		'angular-loading-bar',
		'ui.bootstrap',
		// 'angular-google-analytics',
		'credit-cards',
	])
	.constant('BASE_URL', DEVMODE ? 'http://localhost:8080/' : '/')
	.constant('BUILD_DATE', '{{BUILD_DATE}}')
// 	.config(function (AnalyticsProvider) {
// 		// set flags for analytics local or production
// 		AnalyticsProvider.setDomainName(DEVMODE ? 'none' : '360works.com');
// 		AnalyticsProvider.useECommerce(true, true);
// 		AnalyticsProvider.enterDebugMode(DEVMODE); // remove me once working
// 		AnalyticsProvider.logAllCalls(true);
// 		AnalyticsProvider.setAccount(
// 			{
// 				tracker: 'G-04X3T0WEG9',
// 				name: 'store',
// /*
// 				fields: {
// 					cookieDomain: 'foo.example.com',
// 					cookieName: 'myNewName',
// 					cookieExpires: 20000
// 					// See: [Analytics Field Reference](https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference) for a list of all fields.
// 				},
// */
// /*
// 				crossDomainLinker: true,
// 				crossLinkDomains: ['360works.com', 'store.360works.com'],
// */
// 				// displayFeatures: true,
// 				// enhancedLinkAttribution: true,
// /*
// 				select: function (args) {
// 					// This function is used to qualify or disqualify an account object to be run with commands.
// 					// If the function does not exist, is not a function, or returns true then the account object will qualify.
// 					// If the function exists and returns false then the account object will be disqualified.
// 					// The 'args' parameter is the set of arguments (which contains the command name) that will be sent to Universal Analytics.
// 					return true;
// 				},
// */
// /*
// 				set: {
// 					forceSSL: true
// 					// This is any set of `set` commands to make for the account immediately after the `create` command for the account.
// 					// The property key is the command and the property value is passed in as the argument, _e.g._, `ga('set', 'forceSSL', true)`.
// 					// Order of commands is not guaranteed as it is dependent on the implementation of the `for (property in object)` iterator.
// 				},
// */
// 				trackEvent: true,
// 				trackEcommerce: true
// 			}
// 		); // should this be "UA-1220099-1" or "G-04X3T0WEG9"?
// 	})
	.config(function ($sceProvider) {
		// Completely disable SCE, allowing ng-bind-html
		$sceProvider.enabled(false);
	})
	.config(function ($httpProvider) {
		// allow $http to send auth header to our server
		$httpProvider.defaults.withCredentials = true;
	})
	.config(function ($locationProvider) {
		// we allow fancy URLs without a hash, thanks to {@link rewrite.config}
		$locationProvider.html5Mode(true);
	})
	.config(function ($uibModalProvider) {
		$uibModalProvider.options.size = 'lg';
		$uibModalProvider.options.backdrop = 'static';
		$uibModalProvider.options.animation = true;
	})
	.config(function ($routeProvider, BUILD_DATE) {
		// noinspection JSUnusedGlobalSymbols
		$routeProvider
			.when('/', {
				templateUrl: 'views/main.html?v=' + BUILD_DATE,
				controller: 'MainCtrl',
				resolve: {
					products: function (api) {
						return api.getProducts();
					}
				}
			})
			.when('/add-product/:code', {
				reloadOnSearch: false,
				templateUrl: 'views/add-product.html?v=' + BUILD_DATE,
				controller: 'AddProductCtrl',
				resolve: {
					product: function ($route, api) {
						var productCode = $route.current.params.code;
						return api.getProduct(productCode);
					}
				}
			})
			.when('/add-portfolio', {
				templateUrl: 'views/add-portfolio.html?v=' + BUILD_DATE,
				controller: 'AddPortfolioCtrl',
				resolve: {
					portfolioVariant: function (api) {
						return api.getProductVariantByProductId(35);
					}
				}
			})
			.when('/add-portfolio-fba', {
				templateUrl: 'views/add-portfolio.html?v=' + BUILD_DATE,
				controller: 'AddPortfolioCtrl',
				resolve: {
					portfolioVariant: function (api) {
						return api.getProductVariant(182); // FBA portfolio product variant
					}
				}
			})
			.when('/mirrorsync', {
				templateUrl: 'views/mirrorsync.html?v=' + BUILD_DATE,
				controller: 'MirrorSyncCtrl',
				resolve: {
					support: function (api) {
						return api.mirrorSyncPricing();
					}
				}
			})
			.when('/checkout', {
				templateUrl: 'views/checkout.html?v=' + BUILD_DATE,
				controller: 'CheckoutCtrl',
			})
			.when('/receipt', {
				templateUrl: 'views/receipt.html?v=' + BUILD_DATE,
				controller: 'ReceiptCtrl',
				resolve: {
					receiptHtml: function ($route, api) {
						return api.getReceiptHtml($route.current.params.licenseKey, $route.current.params.cartId);
					}
				}
			})
			.when('/login', {
				templateUrl: 'views/login.html?v=' + BUILD_DATE,
				controller: 'LoginCtrl',
			})
			.when('/reset-password', {
				templateUrl: 'views/reset-password.html?v=' + BUILD_DATE,
				controller: 'ResetPasswordCtrl',
			})
			.when('/licenses', {
				templateUrl: 'views/licenses.html?v=' + BUILD_DATE,
				controller: 'LicensesCtrl',
			})
			.when('/renew', {
				redirectTo: function (params, path, search) {
					return '/licenses?licenseKey=' + (search.licenseKey || '') + '&registeredTo=' + (search.registeredTo || '');
				}
				// templateUrl: 'views/licenses.html?v=' + BUILD_DATE,
				// controller: 'LicensesCtrl',
			})
			.when('/admin', {
				templateUrl: 'views/admin.html?v=' + BUILD_DATE,
				controller: 'AdminCtrl',
				reloadOnSearch: false,
				resolve: {
					user: function (api) {
						return api.adminCurrentUser();
					}
				}
			})
			.when('/reports', {
				templateUrl: 'views/reports.html?v=' + BUILD_DATE,
				controller: 'ReportsCtrl',
				resolve: {
					user: function (api) {
						return api.adminCurrentUser();
					}
				}
			})
			.when('/reports/promo-code-sales', {
				templateUrl: 'views/reports-promo-code-sales.html?v=' + BUILD_DATE,
				controller: 'ReportsPromoCodeSalesCtrl',
			})
			.when('/reports/sales-by-date', {
				templateUrl: 'views/reports-sales-by-date.html?v=' + BUILD_DATE,
				controller: 'ReportsSalesByDateCtrl',
			})
			/*
						.when('/modal-product-edit', {
							templateUrl: 'views/modal-product-edit.html?v=' + BUILD_DATE,
							controller: 'ModalProductEditCtrl',
							resolve: {
								product: function (api) {
									return api.get('admin/product', {params: {id: 45}});
								}
							}
						})
			*/
			/*
						.when('/modal-upgrade-form', {
							templateUrl: 'views/modal-upgrade-form.html?v=' + BUILD_DATE,
							controller: 'ModalUpgradeFormCtrl',
						})
			*/
			.when('/update-payment-info', { // legacy URL format with named `licenseKey` parameter
				templateUrl: 'views/update-payment-info.html?v=' + BUILD_DATE,
				controller: 'UpdatePaymentInfoCtrl',
				resolve: {
					paymentInfoPayload: ($route, api) => {
						const licenseKey = $route.current.params.licenseKey;
						return api.get('api/update-payment-info?licenseKey=' + licenseKey);
					}
				}
			})
			.when('/update-payment-info/:license', {
				templateUrl: 'views/update-payment-info.html?v=' + BUILD_DATE,
				controller: 'UpdatePaymentInfoCtrl',
				resolve: {
					paymentInfoPayload: ($route, api) => {
						const licenseKey = $route.current.params.license;
						return api.get('api/update-payment-info?licenseKey=' + licenseKey);
					}
				}
			})
			.when('/admin/license-tool', {
				reloadOnSearch: false,
				templateUrl: 'views/admin-license-tool.html?v=' + BUILD_DATE,
				controller: 'AdminLicenseToolCtrl',
			})
			.when('/unsubscribe', {
				templateUrl: 'views/unsubscribe.html',
				controller: 'UnsubscribeCtrl',
			})
			.otherwise({
				redirectTo: '/'
			});
	})
	.config(function () {
		window.toastr.options.timeOut = 15000;
		window.toastr.options.extendedTimeOut = 15000;
	})
	// .run(function (Analytics) {
	// 	console.log('Init analytics' || Analytics); // just need to refer to an instance
	// })
	.run(function ($rootScope, $location, Analytics) {
	    $rootScope.$on('$routeChangeSuccess', function(){
	        // ga('send', 'pageview', $location.path());
			Analytics.track_page_view();
	    });
	})
	.run(function ($rootScope, BUILD_DATE) {
		$rootScope.buildDate = BUILD_DATE;
		$rootScope.year = new Date().getFullYear();
	})
	.run(function (api, $rootScope) { // fetch global settings which live on the server, e.g. valueLists which maybe should be database-driven instead of hard-coded
		api.getServerInfo().then(function (serverInfo) {
			$rootScope.serverInfo = serverInfo;
		});
	});

