'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:ModalLicenseEntryCtrl
 * @description
 * # ModalLicenseEntryCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('ModalLicenseEntryCtrl', function ($scope, api, product, version, variant) {
		$scope.product = product;
		$scope.version = version;
		$scope.variant = variant;

		$scope.license = $scope.$root.license || {
			licenseKey: localStorage.getItem('licenseKey'),
			registeredTo: localStorage.getItem('registeredTo')
		};

		$scope.lookupLicense = function () {
			if ($scope.myForm.$invalid) {
				return;
			}
			api.getLicense($scope.license.licenseKey, $scope.license.registeredTo, product.id, null)
				.then(function(result) {
					$scope.$root.license = $scope.license;
					localStorage.setItem('licenseKey', $scope.license.licenseKey);
					localStorage.setItem('registeredTo', $scope.license.registeredTo);
					return result;
				})
				.then($scope.$close);
		};
	});
