'use strict';

/**
 * Used to ensure password and password-verify match
 * @ngdoc directive
 * @name 360StoreAngularApp.directive:compareTo
 * @description
 * # compareTo
 */
angular.module('360StoreAngularApp')
	.directive('compareTo', function () {
		return {
			require: 'ngModel',
			restrict:'A',
			scope: {
				otherModelValue: '=compareTo'
			},
			link: function (scope, element, attributes, ngModel) {

				ngModel.$validators.compareTo = function (modelValue) {
					return modelValue === scope.otherModelValue;
				};

				scope.$watch('otherModelValue', function () {
					ngModel.$validate();
				});
			}
		};
	});
