'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:AddProductCtrl
 * @description
 * # AddProductCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('AddProductCtrl', function ($scope, $location, $uibModal, Analytics, api, session, /**ProductLine*/ product) {
		const UPSELL_ENABLED = false;
		const cf = new Intl.NumberFormat('default', { style: 'currency', currency: 'USD', minimumFractionDigits:0, maximumFractionDigits:0 });

		/**
		 * @type {ProductLine}
		 */
		$scope.product = product;

		/**
		 * @type {?ProductVersion}
		 */
		$scope.selectedVersion = null;

		/**
		 * @type {ProductVariant[]}
		 */
		$scope.variants = [];

		/**
		 * @type {?ProductVariant}
		 */
		$scope.selected = null;

		$scope.showVariantDetails = (/**ProductVariant*/variant, index) => {
			$scope.selectedVariant = variant;
			$location.search('variant', variant.variantType.code);
			Analytics.view_item_details($scope.product, variant, index);
		};

		/**
		 * @param variant {ProductVariant}
		 */
		$scope.priceDisplay = function (variant) {
			if ( !variant ) {
				return '';
			} else if ( $scope.isFree(variant)) {
				return 'Free!';
			} else if( variant.unitPrice) {
				return cf.format(variant.unitPrice);
			} else if ( variant.unitPercent) {
				return '' + variant.unitPercent + '% of purchase cost';
			}
		};

		$scope.chooseLabel = function () {
			return $scope.isFree($scope.selectedVariant) ? 'Download' : 'Buy';
		};

		$scope.isFree = function (variant) {
			return variant && !variant.unitPrice && !variant.unitPercent;
		};

		$scope.hasUpgradePathTo = function (variant) {
			if ( $scope.product.id===35) {
				return null; // no portfolio upgrades
			}
			if (variant && variant.$hasUpgradePathTo === undefined) { // just check it one time
				variant.$hasUpgradePathTo = $scope.selectedVersion.variants.find(function (each) {
					return each.upgradeFromVariant && each.variantType.id === variant.variantType.id;
				});
			}
			return variant && variant.$hasUpgradePathTo;
		};

		function showRenewalDialog(variant) {
			return $uibModal.open({
				templateUrl: 'views/modal-license-entry.html',
				controller: 'ModalLicenseEntryCtrl',
				resolve: {
					product: function () {
						return $scope.product;
					},
					version: function () {
						return $scope.selectedVersion;
					},
					variant: function () {
						return variant;
					}
				}
			}).result.then(function (licenseInfo) {
				session.licenseInfo = licenseInfo;
				$location.path('/renew')
					.search('licenseKey', licenseInfo.licenseKey)
					.search('registeredTo', licenseInfo.registeredTo);
			});
		}

		function showUpsellDialog(variant) {
			console.assert(UPSELL_ENABLED);
			$uibModal.open({
					templateUrl: 'views/modal-upsell.html',
					controller: 'ModalUpsellCtrl',
					scope: $scope
				}
			).result
				.then(function () {
						Analytics.trackEvent('cart', 'declineUpsell', variant.name);
						$scope.checkout();
					},
					function () {
						Analytics.trackEvent('cart', 'cancelUpsell', variant.name);
					});
			$scope.showUpsells = true;
		}

		/**
		 * @param variant {ProductVariant}
		 */
		$scope.choose = function (variant) {
			if (variant.variantType.code === 'RENEWAL') {
				return showRenewalDialog(variant);
			} else if ($scope.product.id === 35) { // redirect to portfolio page
				$location.path('/add-portfolio');
				return;
			}
			// defer adding to cart until upsell dialog is closed, if they cancel and add it would result in duplicates
			Analytics.add_to_cart($scope.product, $scope.selectedVersion, variant);
			api.createCartAction({action: 'Basic', variantId: variant.id})
				.then(function (action) {
					session.addCartAction(action);
					if (UPSELL_ENABLED && $scope.upsells && $scope.upsells.length) {
						showUpsellDialog(variant);
					} else {
						$scope.checkout();
					}
				});
		};

		$scope.viewUpsell = function (product) {
			Analytics.trackEvent('cart', 'visitUpsell', product);
			$location.path('/add-product/' + product.code);
		};

		$scope.checkout = function () {
			$location.path('/checkout').search({});
		};


		// if (product.id !== 35) { // add portfolio as variant option to everything
		// 	api.getProductById(35).then(function (portfolio) {
		// 		var pv = portfolio.versions.pop().variants.find(function (v) {
		// 			return v.isDefault;
		// 		});
		// 		pv.$hasUpgradePathTo = false;
		// 		pv.name = 'Portfolio License';
		// 		pv.portfolio = true;
		// 		product.versions.forEach(function (version) {
		// 			version.variants.push(pv);
		// 		});
		// 	});
		// }

		$scope.upgrade = function (variant) {
			Analytics.trackEvent('cart', 'upgradeClick', variant.name);
			api.getUpgradeVariants($scope.selectedVersion.id, variant.variantType.id)
				.then(function (upgradeOptions) {
					$scope.selectedVariant = variant;
					$scope.upgradeOptions = upgradeOptions;
					if (!$scope.upgradeOptions.length) {
						toastr.error('There are no upgrade paths to the selected variant');
						return;
					}
					$scope.upgradeParams = {licenseKey: null, registeredTo: null};
					return $uibModal.open({
						templateUrl: 'views/modal-upgrade-form.html',
						controller: 'ModalUpgradeFormCtrl',
						size:'md',
						scope: $scope
					}).result;
				})
				.then(function (chosen) {
						Analytics.trackEvent('cart', 'upgradeSelect', chosen);
						$scope.checkout();
					},
					function () {
						Analytics.trackEvent('cart', 'cancelUpgradeSelect', variant.name);
					});
		};

		/*
		///////////////////////////////////////////////////////////////////////////
		// INIT
		///////////////////////////////////////////////////////////////////////////
		*/

		if (product.code === 'MIRRORSYNC') {
			$location.path('/mirrorsync'); // you'r eon the wrong page for this
		} else {
			if (UPSELL_ENABLED) {
				api.getUpsells(product.id).then(function (upsells) {
					$scope.upsells = upsells;
				});
			}
			let versionParam = $location.search().version;
			if (versionParam) {
				$scope.selectedVersion = product.versions.find(function (o) {
					return o.versionNumber === parseInt(versionParam);
				});
			}
			if (!$scope.selectedVersion) {
				$scope.selectedVersion = product.versions[product.versions.length - 1];
			}
			$scope.variants = $scope.selectedVersion.variants.filter(v=>v.featured && !v.upgradeFromVariant);
			/**
			 * @type {string}
			 */
			const variantTypeParam = $location.search().variant;
			if (variantTypeParam) {
				let index = $scope.variants.findIndex(v=>v.variantType.code===variantTypeParam);
				$scope.showVariantDetails($scope.variants[index], index);
			}
		}

	});
