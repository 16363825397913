'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:ModalResellerCheckoutCtrl
 * @description
 * # ModalResellerCheckoutCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('ModalResellerCheckoutCtrl', function ($scope, session, api) {
		$scope.step = session.account ? 'loggedIn' : 'login';
		$scope.credentials = {email:null, password: null};
		$scope.tmp = {};

		$scope.login = function () {
			session.login($scope.credentials).then(function(account) {
				if (!account.promoCodes || !account.promoCodes.length) {
					window.toastr.error( 'Your account is not linked to a reseller code. Please contact us to fix this issue');
					return;
				}
				$scope.account = account;
				$scope.step = 'loggedIn';
				$scope.applyPromoCode($scope.promo().code);
			});
		};

		$scope.enterDiscountCode = function () {
			api.getPromoCode($scope.tmp.promoCode).then(function (promo) {
				if (promo.isUnique || !promo.discountOnReport || promo.expiresInDays || promo.expirationDate) {
					return window.toastr.warning('This promo code is not eligible for reseller service. Please call us to set up your account.');
				}
				$scope.promo = promo;
				$scope.step = 'createAccount';
			});
		};

		$scope.setupMyAccount = function () {
			if (!$scope.promo) {
				return window.toastr.warning('No elgible promo');
			}
			api.createAccount($scope.promo.code, $scope.credentials.email, $scope.credentials.password).then(function () {
				window.toastr.success('A verification email has been sent to ' + $scope.credentials.email + '. Verify your email, then log in normally.');
				$scope.state = 'login';
			});
		};

		$scope.forgotPassword = function () {
			$scope.step = 'forgotPassword';
		};

		$scope.startOver = function () {
			$scope.step = 'login';
		};

		$scope.resetPassword = function () {
			api.resetPassword($scope.credentials.email).then(function() {
				toastr.success('Password reset instructions have been sent to ' + $scope.credentials.email, 'Reset Password');
			});
		};

		$scope.chooseDiscount = function () {
			$scope.cart.commissionChoice = 'Discount';
			$scope.$close();
		};

		$scope.chooseSendCheck = function () {
			$scope.cart.commissionChoice = 'Check';
			$scope.$close();
		};

		$scope.promo = function () {
			return session.account.promoCodes[0];
		};
	});
