'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('MainCtrl', function ($scope, session, products) {
		$scope.products = products;
		$scope.licenseKey = localStorage.getItem('licenseKey');

		$scope.clearCart = function () {
			session.clearCart();
		};
	});
