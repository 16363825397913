'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:LoginCtrl
 * @description
 * # LoginCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('LoginCtrl', function ($rootScope, $scope, $location, session) {
		$scope.credentials = {username: null, password: null};

		$scope.doLogin = function () {
			session.login($scope.credentials).then(function () {
				window.toastr.success('Login successful!');
				if ($rootScope.savedRoute) {
					$location.path($rootScope.savedRoute.path).search($rootScope.savedRoute.search);
					delete $rootScope.savedRoute;
				} else {
					$location.path($scope.postLoginPath || '/');
				}
			});
		};
	});
