'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:AddPortfolioCtrl
 * @description
 * # AddPortfolioCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('AddPortfolioCtrl', function ($scope, $location, session, api, portfolioVariant) {
		$scope.portfolioVariant = portfolioVariant;

		// @see PortfolioRebateServlet.Payload
		$scope.tmpLicense = {
			licenseKey: null,
			registeredTo: null,
		};

		$scope.tmp = {
			didPurchase: null
		};

		$scope.cartAction = null;

		$scope.addLicense = function () {
			var tmpAction = angular.copy($scope.cartAction);
			tmpAction.portfolioRebateLicenses.push($scope.tmpLicense);
			api.addPortfolioRebate(tmpAction)
				.then(function (updatedAction) {
					$scope.cartAction = updatedAction;
					$scope.tmpLicense.licenseKey = null; // ready to add another
					toastr.success('Up to 80% of the cost of the portfolio license can be redeemed with previously purchased license codes.', 'Your rebate has been applied!');
					document.getElementById('portfolio-rebate-license-key').focus(); // ready to paste in next license
				});
		};

		$scope.removePreviousPurchase = function (index) {
			$scope.cartAction.portfolioRebateLicenses.splice(index, 1);
		};

		$scope.adjustedPrice = function () {
			return $scope.cartAction.lineItems
				.map(function (l) {
					return l.unitPrice * l.qty;
				})
				.reduce(function (previousValue, currentValue) {
					return previousValue + currentValue;
				}, 0);
		};

		$scope.doneAdding = function () {
			session.clearCart(); // only Portfolio allowed in the cart
			session.addCartAction($scope.cartAction);
			$location.path('/checkout');
		};

		/*
		///////////////////////////////////////////////////////////////////////////
		// INIT
		///////////////////////////////////////////////////////////////////////////
		*/
		// create a single line item for just portfolio
		api.createCartAction({
			action: 'Portfolio',
			variantId: portfolioVariant.id // enterprise portfolio
		}).then(function(newAction) {
			newAction.portfolioRebateLicenses = [];
			$scope.cartAction = newAction;
			if (portfolioVariant.variantType.code==='FBA') {
				$scope.doneAdding();
			}
		});
	});
