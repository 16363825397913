'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:ReportsCtrl
 * @description
 * # ReportsCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('ReportsCtrl', function ($scope, user) {
		$scope.user = user;
	});
