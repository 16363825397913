'use strict';

/**
 * HTML HEADER: <th><st-sort sort-by="name" sort-model="sortModel">Name</st-sort></th>
 * HTML REPEAT: <tr ng-repeat="c in contacts | orderBy:sortModel.key:sortModel.descending track by c.id">
 * JS: $scope.sortModel = {};
 * @ngdoc directive
 * @name 360StoreAngularApp.directive:stSort
 * @description
 * # stSort
 */
angular.module('360StoreAngularApp')
	.directive('stSort', function () {
		return {
			restrict: 'E',
			transclude: true,
			template: '<a ng-click="onClick()">' +
				'<span ng-transclude></span> ' +
				'<i class="glyphicon" ng-class="setHeaderIcon()" ng-style="style()"></i>' +
				'</a>',
			scope: {
				sortBy: '@',
				sortModel: '='
			},
			link: function (scope) {
				scope.onClick = function () {
					// $window.ga('send', 'event', 'DriveAssignments', 'sort', null, column + '_' + ($scope.reverse ? 'DESC' : 'ASC'));
					if (scope.sortModel.key === scope.sortBy) {
						scope.sortModel.descending = !scope.sortModel.descending;
					} else {
						scope.sortModel.key = scope.sortBy;
						scope.sortModel.descending = false;
					}
				};

				scope.setHeaderIcon = function () {
					if (scope.sortModel && scope.sortModel.key === scope.sortBy) {
						return scope.sortModel.descending ? 'glyphicon-sort-by-attributes-alt' : 'glyphicon-sort-by-attributes';
					}
					return 'glyphicon-sort-by-attributes-alt';
				};

				scope.style = function () {
					// This will keep columns in a table from shifting if you use x-glyphicon-none
					if (scope.sortModel && scope.sortModel.key === scope.sortBy) {
						return {'visibility': 'visible'};
					} else {
						return {'visibility': 'hidden'};

					}
				};
			}
		};
	});
