'use strict';

/**
 * @ngdoc directive
 * @name 360StoreAngularApp.directive:licenseKeyInput
 * @description
 * # licenseKeyInput
 */
angular.module('360StoreAngularApp')
	.directive('licenseKeyInput', function () {
		return {
			restrict: 'C',
			require:'ngModel',
			link: function postLink(scope, element, attrs, ngModel) {
				ngModel.$validators.licenseKey = function (modelValue, viewValue) {
					if (viewValue) {
						return (viewValue.match(/[C-D][A-Z0-9]{23,39}/));
					}
					return true;
				};
			}
		};
	});
