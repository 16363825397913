'use strict';

/**
 * @ngdoc service
 * @name 360StoreAngularApp.session
 * @description
 * # session
 * Service in the 360StoreAngularApp.
 */
angular.module('360StoreAngularApp')
	.service('session', function (Analytics, api) {
		const that = this;

		/**
		 * @type {CartAction[]}
		 */
		that.cartActions = null;
		that.account = null;

		that.reset = function() {
			that.cartActions = JSON.parse(localStorage.getItem('cartActions')) || [];
		};

		function _persistCart() {
			localStorage.setItem('cartActions', JSON.stringify(that.cartActions));
		}

		that.addCartAction = function (/**CartAction*/ action) {
			// should we just clear the cart when adding a thing? Who orders multiple different products in one go?
			// Yeah, we should.
			this.cartActions = Array.isArray(action) ? action : [action];
			_persistCart();
			return action;
		};

		that.removeCartAction = function(cartAction) {
			const index = that.cartActions.indexOf(cartAction);
			if ( index === -1) {
				return;
			}
			/**
			 * @type {CartAction}
			 */
			const removed = that.cartActions.splice(index, 1)[0];
			try {
				Analytics.remove_from_cart(removed);
			} catch (e) {
				console.warn('Unable to log removal', e);
			}
			_persistCart();
		};


		that.login = function (credentials) {
			return api.login(credentials)
				.then(function (account) {
					that.account = account;
					return account;
				});
		};

		that.clearCart = function () {
			that.cartActions = [];
			_persistCart();
		};

		that.reset();
	});
