'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:ModalUpgradeFormCtrl
 * @description
 * # ModalUpgradeFormCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('ModalUpgradeFormCtrl', function ($scope, api, session) {
		$scope.choose = function (variant) {
			if ($scope.modalForm.$invalid) {
				$scope.modalForm.$setSubmitted();
				toastr.warning('Please fix form validation first');
				return;
			}
			api.createCartAction({action: 'Upgrade', variantId: variant.id, license: $scope.upgradeParams})
				.then(function (action) {
					session.addCartAction(action);
					$scope.$close(variant);
				});
		};
	});
