'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:ResetPasswordCtrl
 * @description
 * # ResetPasswordCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('ResetPasswordCtrl', function ($scope, $location, session, api) {
		$scope.credentials = {
			token: $location.search().token
		};

		if (!$scope.credentials.token) {
			toastr.error('No token was specified');
			$location.path('/');
		} else {
			api.getAccountTmpToken($scope.credentials.token).then(function (tmp) {
				$scope.credentials.email = tmp.accountEmail;
			});
		}

		$scope.resetPassword = function () {
			api.resetPasswordPut($scope.credentials).then(function () {
				session.login($scope.credentials)
					.then(function() {
						$location.path('/');
					});
			});
		};
	});
