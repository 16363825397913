// noinspection JSUnresolvedVariable

'use strict';

/**
 * @ngdoc service
 * @name 360StoreAngularApp.api
 * @description
 * # api
 * Service in the 360StoreAngularApp.
 */
angular.module('360StoreAngularApp')
	.service('api', function ($q, $http, $rootScope, $location, BASE_URL) {

		function unwrapResponse(response) {
			return response.data;
		}

		function handleError(err) {
			console.error(err);
			const errorOverride = err && err.headers && err.headers() && err.headers().proscerror;
			if (errorOverride) {
				toastr.warning(errorOverride);
			} else if (err.status === 401) {
				$rootScope.savedRoute = {path: $location.path(), search: $location.search() || ''};
				toastr.warning('You must log in to access this section');
				if ($location.path().indexOf('login') === -1) {
					$rootScope.postLoginPath = $location.path();
				}
				$location.path('/login');
			} else if (err.status === 404) {
				toastr.error('Bad request (404)');
			} else if (err.status <= 0) {
				toastr.warning('The server is not responding, please try again in a minute');
			} else {
				toastr.error('Unexpected error from server (' + err.status + ')');
			}
			throw err;
		}

		$rootScope.requestInProgress = 0;

		return {

			/*
			///////////////////////////////////////////////////////////////////////////
			// INTERNAL FUNCTIONS
			// Generally these should not be called directly, use a specific named
			// function instead
			///////////////////////////////////////////////////////////////////////////
			*/
			get: function (url, config) {
				$rootScope.requestInProgress += 1;
				return $http.get(BASE_URL + url, config).then(unwrapResponse, handleError)
					.finally(function () {
						$rootScope.requestInProgress -= 1;
					});
			},

			post: function (url, payload, config) {
				$rootScope.requestInProgress += 1;
				return $http.post(BASE_URL + url, payload, config).then(unwrapResponse, handleError)
					.finally(function () {
						$rootScope.requestInProgress -= 1;
					});
			},

			put: function (url, payload, config) {
				$rootScope.requestInProgress += 1;
				return $http.put(BASE_URL + url, payload, config).then(unwrapResponse, handleError)
					.finally(function () {
						$rootScope.requestInProgress -= 1;
					});
			},

			del: function (url, config) {
				$rootScope.requestInProgress += 1;
				return $http.delete(BASE_URL + url, config).then(unwrapResponse, handleError)
					.finally(function () {
						$rootScope.requestInProgress -= 1;
					});
			},

			/*
			///////////////////////////////////////////////////////////////////////////
			// CUSTOM FUNCTIONS
			///////////////////////////////////////////////////////////////////////////
			*/

			/**
			 * @param action {CartAction | Array<CartAction>}
			 * @return {*}
			 */
			createCartAction: function (action) {
				if (angular.isArray(action)) { // batch
					return this.post('api/add-cart-action-batch', action);
				} else {
					return this.post('api/add-cart-action', action);
				}
			},

			/**
			 * @param payload {CartAction}
			 * @return {Promise<CartAction>}
			 */
			addPortfolioRebate: function (payload) {
				return this.post('api/portfolio-rebate', payload);
			},

			// addMirrorSync: function (payload) {
			// 	return this.post('api/mirrorsync-checkout', payload);
			// },

			/**
			 * @return {Promise<ShoppingCart>}
			 */
			getCartForCheckout(cartActions) {
				return this.post('api/cart-for-checkout', cartActions);
			},
			checkout: function (cart) {
				return this.post('api/checkout', cart);
			},

			createAccount: function (promoCode, email, password) {
				return this.post('api/create-account', null, {params: {promoCode: promoCode, email: email, password: password}});
			},

			updatePaymentInfo: function (payment, licenseKey) {
				return this.post('api/update-payment-info', payment, {params: {licenseKey}});
			},

			/**
			 * Use `session.login` instead, which saves the resulting account in session.
			 * @param credentials contains email / password
			 * @returns account promise
			 */
			login: function (credentials) {
				return this.post('api/login', null, {params: credentials});
			},

			getAccountTmpToken: function (token) {
				return this.get('api/account-tmp-token', {params: {token: token}});
			},

			/**
			 * @param licenseKey {String}
			 * @param registeredTo {String}
			 * @param productId {String}
			 * @param upgradeTo {String}
			 * @return {Promise<LicenseInfo>}
			 */
			getLicense: function (licenseKey, registeredTo, productId, upgradeTo) {
				return this.get('api/license', {params: {licenseKey: licenseKey, registeredTo: registeredTo, productId: productId, upgradeTo: upgradeTo}});
			},

			getProduct: function (code) {
				return this.get('api/product', {params: {code: code}});
			},

			getProductById: function (id) {
				return this.get('api/product', {params: {id: id}});
			},

			getRenewalCandidates: function (licenseKey, registeredTo) {
				return this.get('api/renewal-candidates', {params: {licenseKey: licenseKey, registeredTo: registeredTo}});
			},

			getUpsells: function (productId) {
				return this.get('api/products-upsell', {params: {productId: productId}});
			},

			getProducts: function () {
				return this.get('api/products');
			},

			getProductsAll: function () {
				return this.get('api/products-all');
			},

			getProductVariant: function (id) {
				return this.get('api/product-variant', {params: {id: id}});
			},

			getProductVariantByProductId: function (productId, optionalVariantType) {
				return this.get('api/product-variant-by-product-id', {params: {productId: productId, variantType: optionalVariantType}});
			},

			getPromoCode: function (promoCode) {
				return this.get('api/promo-code', {params: {code: promoCode}});
			},

			getReceiptHtml: function (licenseKey, cartId) {
				return this.get('api/receipt-html', {params: {licenseKey: licenseKey, cartId:cartId}});
			},

			getServerInfo: function () {
				return this.get('api/server-info');
			},

			mirrorSyncPricing: function () {
				return this.get('api/mirrorsync-pricing');
			},

			postMirrorSyncCustomForm: function(custom) {
				return this.post('api/mirrorsync-custom-form', custom);
			},

			resetPassword: function (email) {
				return this.post('api/reset-password', null, {params: {email: email}});
			},
			resetPasswordPut: function (credentials) {
				return this.put('api/reset-password', null, {params: credentials});
			},
			getUpgradeVariants: function (versionId, variantTypeId) {
				return this.get('api/variant-upgrade-options', {params: {versionId: versionId, variantTypeId: variantTypeId}});
			},

			/*
			///////////////////////////////////////////////////////////////////////////
			// ADMIN STUFF
			///////////////////////////////////////////////////////////////////////////
			*/
			saveOrder: function(order) {
				return this.post('admin/orders', order);
			},

			saveProduct: function(product) {
				return this.post('admin/product', product);
			},

			savePromoCode: function(promo) {
				return this.post('admin/promo-codes', promo);
			},

			adminVariantTypes: function() {
				return this.get('admin/variant-types');
			},

			adminCurrentUser: function () {
				return this.get('admin/current-user');
			},
			adminRenewProcess: function (/*ShoppingCart*/ cart) {
				return this.post('admin/renew-process', null, {params:{orderId:cart.id}});
			},
			generateLicenseKey: function (/**GenerateLicenseKeyPayload*/payload) {
				return this.post('admin/generate-license-key', payload);
			},
			generateLicenseKeyForOrderId: function (/**number*/orderId) {
				return this.get('admin/generate-license-key-for-order-id', {params:{orderId}});
			},
			parseLicenseKey: function (/**string*/ licenseKey) {
				return this.get('admin/parse-license-key', {params:{licenseKey}});
			},

			/*
			///////////////////////////////////////////////////////////////////////////
			// REPORTING
			///////////////////////////////////////////////////////////////////////////
			*/
			runReport: function (reportName, args) {
				return this.get('reports/' + reportName, args);
			}
		};

	});
